import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ModuleEditorContext } from "../reducers/ModuleEditorReducer";

import ModuleDescription from "./Details/ModuleDescription";
import ModuleThumbnail from "./Details/ModuleThumbnail";
import WelcomeScreen from "./Details/WelcomeScreen";
import LearningObjectiveScreen from "./Details/LearningObjectiveScreen";
import SurveyQuestionScreen from "./Details/SurveyQuestionScreen";

function Details() {
  const context = useContext(ModuleEditorContext);
  const [dialogOpen, setDialogOpen] = useState(false); // new state to control Dialog visibility
  const [dialogMessage, setDialogMessage] = useState(""); // new state to hold the Dialog message

  if (!context) {
    throw new Error("MomentCard must be used within a ModuleEditorProvider");
  }

  const { dispatch, state } = context;

  const { surveyQuestions, inProgressIds, moduleName, moduleScript } = state;

  async function generateModule() {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/generate_module?module_name=${state.moduleName}&avatar_id=${state.character}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state),
      }
    );
    const json = await res.json();
    console.log(json);
    dispatch({
      type: "SET_IN_PROGRESS_IDS",
      payload: json.video_ids,
    });
  }

  const downloadCSV = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/convert_to_csv?moduleName=${moduleName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(moduleScript),
      }
    );

    // 4. Trigger download of CSV
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${moduleName}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Failed to download CSV.");
    }
  };

  async function publishModule() {
    console.log(inProgressIds);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/retrieve_module`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          module_name: moduleName,
          video_ids: inProgressIds,
        }),
      }
    );
    const json = await res.json();
    if (json["status"] === "incomplete") {
      setDialogMessage("You have not generated all of the videos yet!");
    } else {
      setDialogMessage("Module published!");
    }
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box>
      <ModuleDescription />
      <ModuleThumbnail />
      <WelcomeScreen />
      <LearningObjectiveScreen />
      <SurveyQuestionScreen surveyQuestion={surveyQuestions[0]} questionNumber={1} />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => generateModule()}
        >
          Generate Module!
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => downloadCSV()}
        >
          Download CSV
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!inProgressIds}
          onClick={() => publishModule()}
        >
          Publish Module
        </Button>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Module Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Details;
