import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Paper,
  Button,
  Breadcrumbs,
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { Link, useParams } from 'react-router-dom';

import { ModuleEditorContext, setFields } from "../reducers/ModuleEditorReducer";

import "./ModuleEditor.css";

import { TabPanelProps } from "../types";

import ChatContainer from "./ChatContainer";
import Character from "./Character";
import Script from "./Script";
import Feedback from "./Feedback";
import Details from "./Details";

import { logoutUser } from '../hooks/useLogin';

const steps = ["1. Scene setup", "2. Character", "3. Script", "4. Feedback", "5. Details"];

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ModuleEditor({ onLogout } : { onLogout: () => void }) {
  const [value, setValue] = useState(0);

  const { moduleName } = useParams();

  console.log('moduleName', moduleName);

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("ModuleEditor must be used within a ModuleEditorContext.Provider");
  }

  const { state, dispatch } = context;
  const { moduleScript, keyMoments } = state;

  const fetchModuleMetadata = async (moduleName: string) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/retrieve_module_metadata?module_name=${moduleName}`);
    const json = await res.json();
    console.log('json', json);
    dispatch({
      type: "LOAD_MODULE",
      payload: json
    });
  }

  useEffect(() => {
    if (moduleName) {
      fetchModuleMetadata(moduleName);
      dispatch(setFields({
        moduleName: moduleName
      }))
    }
  }, [moduleName]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link to="/modules" key="home-link"><HomeIcon /></Link>,
    <Typography fontWeight="bold" fontSize="small" key="3" color="text.primary">
      Create New Module
    </Typography>,
    <Typography fontWeight="bold" fontSize="small" key="4" color="text.primary">{moduleName}</Typography>
  ];

  const handleLogout = () => {
    logoutUser();
    onLogout();
  }

  console.log('state', state);

  return (
    <div className="moduleEditorContainer">
      <Box ml={2} mb={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <Button variant="contained" onClick={() => handleLogout()}>Logout</Button>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: 'flex', // Main Flexbox container
          alignItems: 'center'
        }}
      >
        <Box flexShrink={0} ml={2}> {/* This won't shrink and will take its natural width */}
          <Typography sx={{ fontWeight: 'bold' }}>{state.moduleName}</Typography>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent="center"> {/* This will take up the remaining space and center its children */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              fontWeight: 'bold',  // Make all tab text bold
              textTransform: 'capitalize',
              "&:focus": { // Override the default focus styles here
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent'  // Override any focus-related background color change
              }
            },
            "& .MuiTab-root.Mui-selected": { // Style for the selected tab
              color: 'black',
            },
            "& .MuiTabs-indicator": { // Style for the underline indicator
              display: 'none'
            }
          }}
        >
          {steps.map((step, index) => (
            <Tab label={step} {...a11yProps(index)} disableRipple />
          ))}
          <Link
            to={`/viewer/${moduleName}`}
            style={{
              marginLeft: '16px',
              alignSelf: 'center',
              textDecoration: 'none',
              fontWeight: 'bold',
              color: value === 5 ? 'black' : 'rgba(0, 0, 0, 0.54)', // Sample styling to highlight "viewer" as active when selected.
            }}
          >
            View Module
          </Link>
        </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ChatContainer
          setTab={setValue}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Character setTab={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Script />
        {keyMoments.size === 3 ? (
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              height: '56px', // you can adjust based on your needs
              display: 'flex',
              justifyContent: 'flex-end', // position the content to the right
              alignItems: 'center', // vertically center the content
              paddingRight: 2, // padding on the right
            }}
            elevation={3}
          >
            <Button
              variant="contained"
              onClick={() => setValue(value + 1)}
            >
              Done editing script
            </Button>
          </Paper>
        ) : null}
      </CustomTabPanel>
      {moduleScript && (
        <CustomTabPanel value={value} index={3}>
          <Feedback
            keyMoments={keyMoments}
            moduleScript={moduleScript}
          />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={4}>
        <Details />
      </CustomTabPanel>
    </div>
  );
}

export default ModuleEditor;
