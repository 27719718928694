import { useState, useContext, useEffect } from "react";
import {
  Typography,
  Card,
  Box,
  TextField,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { ModuleEditorContext } from "../reducers/ModuleEditorReducer";

import CommunicationStyles from "./Script/CommunicationStyles";
import MomentCard from "./Script/MomentCard";

const StyledCard = styled(Card)({
  width: "100%",
  height: "auto",
  minHeight: "150px",
  backgroundColor: "rgb(240, 240, 240)",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start", // Aligns items to the left
});

const Moment0Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

function Moment0({ content }: { content: string }) {
  const [editableText, setEditableText] = useState('');

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("Moment0 must be used within a ModuleEditorContext.Provider");
  }

  const { state, dispatch } = context;

  useEffect(() => {
    setEditableText(content);
  }, [content]);

  return (
    <Moment0Container>
      <Typography>Moment 0</Typography>
      <StyledCard sx={{ width: "60%" }}>
        <Typography mb={2} fontWeight="bold">
          Dialog 1
        </Typography>
        <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            rows={4}
            value={editableText}
            onChange={(e) => setEditableText(e.target.value)}
            onBlur={() => {
              dispatch({
                type: "EDIT_MOMENT0",
                payload: editableText
              });
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default Enter key action.
                dispatch({
                  type: "EDIT_MOMENT",
                  payload: editableText
                });
              }
            }}
          />
      </StyledCard>
    </Moment0Container>
  );
}

function Script() {
  const [communicationStyleAOptions] = useState([
    "Direct",
    "Empathetic",
    "Supportive",
    "Constructive",
    "Inclusive",
    "Effective",
    "Concerned",
    "Motivational",
  ]);
  const [communicationStyleBOptions] = useState([
    "Diagnostic",
    "Self-Focused",
    "Defensive",
    "Leading",
    "Vague",
    "Passive",
    "Dismissive",
    "Overemotional",
    "Rude"
  ]);
  const [communicationStyleCOptions] = useState([
    "Directive",
    "Ineffective",
    "Critical",
    "Hostile",
    "Authoritative",
    "Avoidant",
    "Polarized",
    "Microaggressive",
  ]);

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("Script must be used within a ModuleEditorContext.Provider");
  }

  const { state } = context;
  const { moduleScript, scriptLoading } = state;

  const numberOfMoments = moduleScript && moduleScript.pathA.moments.length;

  return (
    <Box position="relative">
      <CommunicationStyles
        communicationStyleAOptions={communicationStyleAOptions}
        communicationStyleBOptions={communicationStyleBOptions}
        communicationStyleCOptions={communicationStyleCOptions}
      />
      <Card
        sx={{
          padding: 2,
          my: 2,
          boxShadow: "none",
          border: "1px solid lightgrey",
          borderRadius: "10px",
          backgroundColor: scriptLoading ? "rgba(211,211,211, 0.5)" : "white", // Dimmed Background
          pointerEvents: scriptLoading ? "none" : "auto" // Disable interactions
        }}
      >
        {moduleScript && <Moment0 content={moduleScript['moment0']} />}
        {moduleScript && [...Array(numberOfMoments)].map((moment, i) => (
          <MomentCard
            momentNumber={i}
            pathA={moduleScript['pathA']['moments'][i]}
            pathB={moduleScript['pathB']['moments'][i]}
            pathC={moduleScript['pathC']['moments'][i]}
          />
        ))}
      </Card>
      {scriptLoading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Added for vertical stacking
            alignItems: 'center', // Center content horizontally
            justifyContent: 'flex-start', // Align to the start of the flex container
            textAlign: 'center', // Center text
            position: 'absolute',
            top: '5em',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            paddingTop: '16px',
          }}
        >
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}>Generating Script, this should take about 5 minutes...</Typography>
        </Box>
      )}
    </Box>
  );
}

export default Script;
