import { useState } from 'react';
import {
  Typography,
  Card,
  TextField,
  Box
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface SurveyQuestionScreenProps {
  surveyQuestion: string;
  questionNumber: number;
}

function SurveyQuestionScreen({ surveyQuestion, questionNumber }: SurveyQuestionScreenProps) {
  const [userInput, setUserInput] = useState(surveyQuestion);

  return (
    <Card
      sx={{
        padding: 2,
        my: 2,
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography>How confident are you in </Typography>
        {/* User input field */}
        <TextField
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="answer here"
          variant="outlined"
          size="small"
          sx={{
            ml: 1, // adds some margin to the left of the input
            maxWidth: '200px' // you can adjust the maximum width as needed
          }}
        />
        <Typography>?</Typography>
      </Box>
    </Card>
  );
}

export default SurveyQuestionScreen;