
import { useState, useContext } from "react";
import {
  Typography,
  Paper,
  Grid,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { ModuleEditorContext, setFields } from "../reducers/ModuleEditorReducer";

import "./Character.css";
import alisha from '../images/characters/alisha.png';
import emma from '../images/characters/emma.png';
import evelyn from '../images/characters/evelyn.png';
import jimmy from '../images/characters/jimmy.png';
import parker from '../images/characters/parker.png';
import sally from '../images/characters/sally.png';
import sana from '../images/characters/sana.png';
import will from '../images/characters/will.png';

import envOne from '../images/backgrounds/01598-2940926950.png';
import envTwo from '../images/backgrounds/01599-994946132.png';
import envThree from '../images/backgrounds/01601-2980329448.png';
import envFour from '../images/backgrounds/01602-1266289809.png';
import envFive from '../images/backgrounds/01603-3362907080.png';
import envSix from '../images/backgrounds/01604-2883354325.png';
import envSeven from '../images/backgrounds/01605-3283836370.png';
import envEight from '../images/backgrounds/01606-2102328403.png';
import envNine from '../images/backgrounds/01607-3707689381.png';
import envTen from '../images/backgrounds/01608-835471515.png';

const pics = {
  '05c90ba7-33e4-49ac-bc6e-3840973a31fc': {
    image: alisha,
    name: 'Alisha',
    id: '05c90ba7-33e4-49ac-bc6e-3840973a31fc',
    voiceId: '21m00Tcm4TlvDq8ikWAM'
  },
  'be374328-34cd-48ec-9f6c-9b5393024af3': {
    image: emma,
    name: 'Emma',
    id: 'be374328-34cd-48ec-9f6c-9b5393024af3',
    voiceId: '21m00Tcm4TlvDq8ikWAM'
  },
  '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd': {
    image: evelyn,
    name: 'Evelyn',
    id: '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd',
    voiceId: '21m00Tcm4TlvDq8ikWAM'
  },
  '8fe0e460-99a3-445b-a244-0518cf1cc2d2': {
    image: jimmy,
    name: 'Jimmy',
    id: '8fe0e460-99a3-445b-a244-0518cf1cc2d2',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3'
  },
  '888255b8-7311-4047-99c3-b4d9fa306712': {
    image: parker,
    name: 'Parker',
    id: '888255b8-7311-4047-99c3-b4d9fa306712',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3'
  },
  '5ebb954e-c5ea-40f3-be6e-a7a23923c13b': {
    image: sally,
    name: 'Sally',
    id: '5ebb954e-c5ea-40f3-be6e-a7a23923c13b',
    voiceId: '21m00Tcm4TlvDq8ikWAM'
  },
  '4095cd7c-40ab-4134-9c76-80f07c845b31': {
    image: sana,
    name: 'Sana',
    id: '4095cd7c-40ab-4134-9c76-80f07c845b31',
    voiceId: '21m00Tcm4TlvDq8ikWAM'
  },
  '4612d242-cbb6-4993-8b18-ec62e1706bcf': {
    image: will,
    name: 'Will',
    id: '4612d242-cbb6-4993-8b18-ec62e1706bcf',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3'
  }
}

const envs = {
  '01598-2940926950': {
    image: envOne,
    name: 'Environment 1',
    id: '01598-2940926950'
  },
  '01599-994946132': {
    image: envTwo,
    name: 'Environment 2',
    id: '01599-994946132'
  },
  '01601-2980329448': {
    image: envThree,
    name: 'Environment 3',
    id: '01601-2980329448'
  },
  '01602-1266289809': {
    image: envFour,
    name: 'Environment 4',
    id: '01602-1266289809'
  },
  '01603-3362907080': {
    image: envFive,
    name: 'Environment 5',
    id: '01603-3362907080'
  },
  '01604-2883354325': {
    image: envSix,
    name: 'Environment 6',
    id: '01604-2883354325'
  },
  '01605-3283836370': {
    image: envSeven,
    name: 'Environment 7',
    id: '01605-3283836370'
  },
  '01606-2102328403': {
    image: envEight,
    name: 'Environment 8',
    id: '01606-2102328403'
  },
  '01607-3707689381': {
    image: envNine,
    name: 'Environment 9',
    id: '01607-3707689381'
  },
  '01608-835471515': {
    image: envTen,
    name: 'Environment 10',
    id: '01608-835471515'
  }
};

const StyledCard = styled(Card)({
  width: "100%",
  height: "auto",
  minHeight: "150px",
  backgroundColor: "white",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start", // Aligns items to the left
});

function Character({ setTab } : { setTab: (tab: number) => void}) {
  // const [selectedCharacter, setSelectedCharacter] = useState<string | null>(null);

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("ChatContainer must be used within a ModuleEditorContext.Provider");
  }

  const { state, dispatch } = context;
  // const [_, ...messages] = state.messages;
  const { character, environment } = state;

  const handleSelectCharacter = (char: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/update_module_metadata`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          character: char,
          characterName: pics[char as keyof typeof pics]['name'],
          moduleName: state.moduleName,
          voiceId: pics[char as keyof typeof pics]['voiceId']
      }),
    });
    dispatch(setFields({
      character: char,
      characterName: pics[char as keyof typeof pics]['name'],
      voiceId: pics[char as keyof typeof pics]['voiceId']
    }))
  }

  const handleSelectEnvironment = (env: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/update_module_metadata`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          environment: env,
          moduleName: state.moduleName,
      }),
    });
    dispatch(setFields({
      environment: env
    }))
  }

  return (
    <div>
      <StyledCard sx={{ p: 3, m: 3 }}>
        <Grid container spacing={2}>
        {Object.keys(pics).map((char, index) => (
          <Grid key={index} item xs={3}>
          <Card
            key={index}
            onClick={() => handleSelectCharacter(char)}
            sx={{
              backgroundColor: 'rgb(240, 240, 240)',
              borderRadius: '0.5rem',
              border: character && char === character ? '3px solid rgb(68, 41, 151)' : '3px solid rgb(222, 222, 222)',
              height: '100%',
            }}>
            <CardActionArea>
              <CardMedia
                component="img"
                image={pics[char as keyof typeof pics]['image']}
                alt="character profile"
              />
              <CardContent sx={{ backgroundColor: 'white' }}>
                <Typography align='left'>{pics[char as keyof typeof pics]['name']}</Typography>
              </CardContent>
            </CardActionArea>
          </Card></Grid>))}
        </Grid>
      </StyledCard>
      <StyledCard sx={{ p: 3, m: 3 }}>
        <Grid container spacing={2}>
        {Object.keys(envs).map((env, index) => (
          <Grid key={index} item xs={3}>
          <Card
            key={index}
            onClick={() => handleSelectEnvironment(env)}
            sx={{
              backgroundColor: 'rgb(240, 240, 240)',
              borderRadius: '0.5rem',
              border: environment && env === environment ? '3px solid rgb(68, 41, 151)' : '3px solid rgb(222, 222, 222)',
              height: '100%',
            }}>
            <CardActionArea>
              <CardMedia
                component="img"
                image={envs[env as keyof typeof envs]['image']}
                alt="character profile"
              />
              <CardContent sx={{ backgroundColor: 'white' }}>
                <Typography align='left'>{envs[env as keyof typeof envs]['name']}</Typography>
              </CardContent>
            </CardActionArea>
          </Card></Grid>))}
        </Grid>
      </StyledCard>
      <Box display="flex" justifyContent="flex-end" m={3}>
        <Button onClick={() => setTab(2)} variant="contained" disabled={character === undefined}>Proceed</Button>
      </Box>
    </div>
  );
}

export default Character;
