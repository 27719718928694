import {Avatar, Box, Chip, Typography} from "@mui/material";

import { ChatMessageProps } from "../types";

export default function Message(props: ChatMessageProps) {
  return (
    <div>
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexFlow: "row",
          justifyContent: props.isCustomer ? "right" : "left",
          // fontStyle: !props.isCustomer ? "italic" : null,
          textAlign: props.isCustomer ? 'right': 'left',
        }}
      >
        <Box>
          <Typography gutterBottom variant="body2" component="div" sx={{mt: 1.5}}>
            {props.content}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
