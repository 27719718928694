import { useState, useContext } from 'react';
import { Typography, Card, TextField } from "@mui/material";
import { ModuleEditorContext, setFields } from '../../reducers/ModuleEditorReducer';

function ModuleDescription() {
  const context = useContext(ModuleEditorContext);

  if (!context) {
    throw new Error("MomentCard must be used within a ModuleEditorProvider");
  }

  const { dispatch, state } = context;
  const { summary } = state;

  const [ editableSummary, setEditableSummary ] = useState(summary);

  return (
    <Card
      sx={{
        padding: 5,
        my: 2,
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h5">Module Description</Typography>
      <TextField
        id="outlined-multiline-static"
        fullWidth
        inputProps={{ maxLength: 100 }}
        multiline
        rows={4}
        value={editableSummary}
        onChange={(e) => setEditableSummary(e.target.value)}
        onBlur={() => {
          dispatch(setFields({
            summary: editableSummary,
          }));
        }}
      />
    </Card>
  );
}

export default ModuleDescription;