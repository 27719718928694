import {
  Typography,
  Card,
} from "@mui/material";

function WelcomeScreen() {
  return (
    <Card
      sx={{
        padding: 5,
        my: 2,
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <Typography>Welcome Screen</Typography>
    </Card>
  );
}

export default WelcomeScreen;
