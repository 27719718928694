import { useState, useContext } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

import { ModuleScript } from "../types";
import { ModuleEditorContext } from "../reducers/ModuleEditorReducer";

const StyledCard = styled(Card)({
  width: "100%",
  height: "auto",
  minHeight: "150px",
  backgroundColor: "rgb(240, 240, 240)",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start", // Aligns items to the left
});

const HeaderContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "15px",
});

function Feedback({
  keyMoments,
  moduleScript,
}: {
  keyMoments: Set<number>;
  moduleScript: ModuleScript;
}) {

  // Move all useState calls to the top
  const [pathAFeedback, setPathAFeedback] = useState([
    keyMoments.size >= 3 ? moduleScript.pathA.moments[Array.from(keyMoments).sort((a, b) => a - b)[0]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathA.moments[Array.from(keyMoments).sort((a, b) => a - b)[1]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathA.moments[Array.from(keyMoments).sort((a, b) => a - b)[2]].feedback : ""
  ]);

  const [pathBFeedback, setPathBFeedback] = useState([
    keyMoments.size >= 3 ? moduleScript.pathB.moments[Array.from(keyMoments).sort((a, b) => a - b)[0]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathB.moments[Array.from(keyMoments).sort((a, b) => a - b)[1]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathB.moments[Array.from(keyMoments).sort((a, b) => a - b)[2]].feedback : ""
  ]);

  const [pathCFeedback, setPathCFeedback] = useState([
    keyMoments.size >= 3 ? moduleScript.pathC.moments[Array.from(keyMoments).sort((a, b) => a - b)[0]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathC.moments[Array.from(keyMoments).sort((a, b) => a - b)[1]].feedback : "",
    keyMoments.size >= 3 ? moduleScript.pathC.moments[Array.from(keyMoments).sort((a, b) => a - b)[2]].feedback : ""
  ]);

  const context = useContext(ModuleEditorContext);

  // Now do the conditional return
  if (keyMoments.size < 3) {
    return <Typography>Not enough key moments to display feedback</Typography>;
  }

  if (!context) {
    throw new Error("MomentCard must be used within a ModuleEditorProvider");
  }

  const { dispatch, state } = context;
  const { communicationStyleA, communicationStyleB, communicationStyleC } =
    state;

  return (
    <Box>
      {Array.from(keyMoments).map((momentNumber, idx) => (
        <Card
          sx={{
            padding: 2,
            my: 2,
            boxShadow: "none",
            border: "1px solid lightgrey",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={2} alignItems="center" mt={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Learner Feedback for Moment {momentNumber + 1}
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard sx={{ backgroundColor: "#c2f0c9" }}>
                <HeaderContainer>
                  <Typography fontWeight="bold">
                    {communicationStyleA}
                  </Typography>
                </HeaderContainer>
                <Typography>
                  {moduleScript.pathA.moments[momentNumber].learner_text}
                </Typography>
              </StyledCard>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard sx={{ backgroundColor: "#f0f0b5" }}>
                <HeaderContainer>
                  <Typography fontWeight="bold">
                    {communicationStyleB}
                  </Typography>
                </HeaderContainer>
                <Typography>
                  {moduleScript.pathB.moments[momentNumber].learner_text}
                </Typography>
              </StyledCard>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard sx={{ backgroundColor: "#f0c2c2" }}>
                <HeaderContainer>
                  <Typography fontWeight="bold">
                    {communicationStyleC}
                  </Typography>
                </HeaderContainer>
                <Typography>
                  {moduleScript.pathC.moments[momentNumber].learner_text}
                </Typography>
              </StyledCard>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard>
                <HeaderContainer>
                  <Typography fontWeight="bold">Explanation</Typography>
                </HeaderContainer>
                {/* <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={editableFirstFeedback}
                  onChange={(e) => setEditableFirstFeedback(e.target.value)}
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathA", "moments", momentNumber, "feedback"], editableFirstFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathA",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathA.moments[momentNumber],
                          feedback: editableFirstFeedback,
                        },
                      },
                    });
                  }}
                /> */}
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={pathAFeedback[idx]}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPathAFeedback(prevFeedback =>
                      prevFeedback.map((feedback, index) =>
                        index === idx ? newValue : feedback
                      )
                    );
                  }}
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathA", "moments", momentNumber, "feedback"], editableFirstFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathA",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathA.moments[momentNumber],
                          feedback: pathAFeedback[idx],
                        },
                      },
                    });
                  }}
                />
              </StyledCard>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard>
                <HeaderContainer>
                  <Typography fontWeight="bold">Explanation</Typography>
                </HeaderContainer>
                {/* <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={editableSecondFeedback}
                  onChange={(e) =>
                    setEditableSecondFeedback(e.target.value)
                  }
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathB", "moments", momentNumber, "feedback"], editableSecondFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathB",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathB.moments[momentNumber],
                          feedback: editableSecondFeedback,
                        },
                      },
                    });
                  }}
                /> */}
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={pathBFeedback[idx]}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPathBFeedback(prevFeedback =>
                      prevFeedback.map((feedback, index) =>
                        index === idx ? newValue : feedback
                      )
                    );
                  }}
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathA", "moments", momentNumber, "feedback"], editableFirstFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathB",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathB.moments[momentNumber],
                          feedback: pathBFeedback[idx],
                        },
                      },
                    });
                  }}
                />
              </StyledCard>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <StyledCard>
                <HeaderContainer>
                  <Typography fontWeight="bold">Explanation</Typography>
                </HeaderContainer>
                {/* <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={editableThirdFeedback}
                  onChange={(e) => setEditableThirdFeedback(e.target.value)}
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathC", "moments", momentNumber, "feedback"], editableThirdFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathC",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathC.moments[momentNumber],
                          feedback: editableThirdFeedback,
                        },
                      },
                    });
                  }}
                /> */}
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                  multiline
                  rows={4}
                  value={pathCFeedback[idx]}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPathCFeedback(prevFeedback =>
                      prevFeedback.map((feedback, index) =>
                        index === idx ? newValue : feedback
                      )
                    );
                  }}
                  onBlur={() => {
                    // This is when you might want to save the change to the global state or API.
                    // updateScript(["pathA", "moments", momentNumber, "feedback"], editableFirstFeedback);
                    dispatch({
                      type: "EDIT_MOMENT",
                      payload: {
                        path: "pathC",
                        momentIndex: momentNumber,
                        newMoment: {
                          ...moduleScript.pathC.moments[momentNumber],
                          feedback: pathCFeedback[idx],
                        },
                      },
                    });
                  }}
                />
              </StyledCard>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Box>
  );
}

export default Feedback;
