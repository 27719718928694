import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  LinearProgress,
  Typography,
  Container,
  Modal,
  Backdrop,
  Fade
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import ChatMessage from "./ChatMessage";

import { ModuleEditorContext, setFields } from "../reducers/ModuleEditorReducer";

function Chatbot({ setTab } : { setTab: (tab: number) => void }) {
  const messagesListRef = React.useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ctaVisible, setCtaVisible] = useState(false);

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("ChatContainer must be used within a ModuleEditorContext.Provider");
  }

  const { state, dispatch } = context;
  const { messages } = state;

  useEffect(() => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sendMessage(messageInput);
    setMessageInput("");
  };

  const sendMessage = async (content: string) => {
    dispatch({
      type: 'ADD_MESSAGE',
      payload: {
        content: content,
        role: "user"
        }
      }
    )
    setIsLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}/chatbot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ message: content, messages_history: messages }),
    });
    const data = await res.json();
    dispatch({
      type: 'ADD_MESSAGE',
      payload: {
        content: data.response,
        role: "system"
        }
      }
    )
    if (data.topic) {
      const fields = {
        topic: data.topic,
        location: data.location,
        learnerRole: data.learnerRole,
        learningObjective: data.learningObjective,
        conflictArea: data.conflictArea,
        scenario: data.scenario,
        incitingIncident: data.incitingIncident,
        characterPersona: data.characterPersona,
        summary: data.summary
      };

      dispatch(setFields(fields))

      try {
        await fetch(`${process.env.REACT_APP_API_URL}/update_module_metadata`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...fields,
            moduleName: state.moduleName,
          }),
        });
      } catch (error) {
        console.error("Failed to update fields in the backend", error);
        // Dispatch another action if you want to handle the error in your state
      }

      setCtaVisible(true);
    }
    setIsLoading(false);
  }

  // const [_, ...messagesWithoutFirst] = messages;
  const [a, b, c, ...messagesWithoutFirst] = messages;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: '60%', p: 3, mx: 'auto', my: 3 }}>
        <CardContent>
          <Box
            ref={messagesListRef}
            sx={{
              height: 420,
              overflow: "scroll",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: '8px' // Adjust width as necessary
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: 'transparent' // This makes it invisible by default
              },
              "&:hover::-webkit-scrollbar-thumb": {
                backgroundColor: '#aaa' // Color when you hover over the scroll area
              }
            }}
          >
            <Box sx={{ m: 1, mr: 2 }}>
              {messagesWithoutFirst.map((message, index) => (
                <ChatMessage
                  key={index}
                  content={message.content}
                  // image={message.image}
                  isCustomer={message.role === "user"}
                  // choices={message.choices}
                  // handleChoice={sendMessage}
                />
              ))}
              {isLoading ? (
                <Container>
                  <Typography sx={{ fontStyle: "italic" }}>
                    Waiting for response...
                  </Typography>
                  <LinearProgress />
                </Container>
              ) : null}
            </Box>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 2,
              display: "flex",
              flexFlow: "row",
              gap: 1,
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              variant="outlined"
              size="small"
              value={messageInput}
              onChange={(event) => setMessageInput(event.target.value)}
              fullWidth
            />
            <Button variant="contained" type="submit">
              <SendIcon />
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={ctaVisible}
        onClose={() => setCtaVisible(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={ctaVisible}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" id="modal-title">
              You have finished the Scene Setup, please click "Proceed" to continue.
            </Typography>
            <Button onClick={() => {
              // Handle the proceed logic here
              setCtaVisible(false);  // Close the modal
              setTab(1);  // Switch to the next tab
            }}>Proceed</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Chatbot;
