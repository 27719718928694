import React, { useState, useEffect, useReducer } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './aws-config';

import './App.css';

import moduleEditorReducer, { initialState, ModuleEditorContext } from "./reducers/ModuleEditorReducer";
import ModuleList from './components/ModuleList';
import ModuleEditor from './components/ModuleEditor';
import Login from './components/Login';
import Viewer from './components/Viewer';

function App() {
  const [token, setToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [state, dispatch] = useReducer(moduleEditorReducer, initialState);

  useEffect(() => {
    checkAuth();
  }, []);

  function handleLogout() {
    setIsAuthenticated(false);
  }

  function setLogin(token: string) {
    setIsAuthenticated(true);
    setToken(token);
  }

  const router = createBrowserRouter([
    {
      path: "/modules",
      element: isAuthenticated ? (
        <ModuleList onLogout={handleLogout} />
      ) : <Login setLogin={setLogin} />
    },
    {
      path: "/module/:moduleName",
      element: isAuthenticated ? (
        <ModuleEditor onLogout={handleLogout} />
      ) : <Login setLogin={setLogin} />
    },
    {
      path: "/viewer/:moduleName",
      element: isAuthenticated ? (
        <Viewer />
      ) : <Login setLogin={setLogin} />
    },
    {
      path: "/", // or "/login" if you prefer
      element: <Login setLogin={setLogin} />
    }
  ]);

  async function checkAuth() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }

  return (
    <React.StrictMode>
      <ModuleEditorContext.Provider value={{ state, dispatch }}>
        <RouterProvider router={router} />
      </ModuleEditorContext.Provider>
    </React.StrictMode>
  );
}

export default App;
