import { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  TextField,
  Breadcrumbs
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

import ModuleDisplayCard from "./ModuleDisplayCard";
import "./ModuleList.css";

import { logoutUser } from '../hooks/useLogin';

import { TabPanelProps, ModuleScrollProps } from "../types";

import { ModuleEditorContext } from "../reducers/ModuleEditorReducer";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ModuleScroll(props: ModuleScrollProps) {
  return (
    <div>
      {props.modules.map((module) => (
        <Box p={2}>
          <ModuleDisplayCard module={module} />
        </Box>
      ))}
    </div>
  );
}

type CreateDialogProps = {
  onClose: () => void;
  open: boolean;
  setModuleNameInReducer: (name: string) => void;
};

function CreateDialog(props: CreateDialogProps) {
  const [moduleName, setModuleName] = useState("");
  const { onClose, open } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Name new module
        </Typography>
        <TextField
          id="filled-basic"
          label="Type module name..."
          variant="filled"
          value={moduleName}
          onChange={(e) => setModuleName(e.target.value)}
          inputProps={{ style: { width: "40ch" }, maxLength: 40 }}
        />
        <Button
          variant="contained"
          sx={{ width: "50%", my: 2 }}
          onClick={() => {
            props.onClose();
            props.setModuleNameInReducer(moduleName);
            navigate(`/module/${moduleName}`);
          }}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
}

function ModuleList({ onLogout }: { onLogout: () => void }) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      "Script must be used within a ModuleEditorContext.Provider"
    );
  }

  const { state, dispatch } = context;
  const { moduleList } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/retrieve_module_list`
        );
        const json = await res.json();
        console.log(json);

        if (json.module_list) {
          // ensure you're referencing the right property from the response
          dispatch({
            type: "SET_MODULE_LIST",
            payload: json.module_list,
          });
        }
      } catch (error) {
        console.error("Failed to fetch module list:", error);
      }
    };
    fetchData();
  }, []);

  const setModuleName = (moduleName: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/update_module_metadata`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          moduleName: moduleName,
      }),
    });
    dispatch({
      type: "SET_MODULE_NAME",
      payload: moduleName,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  const breadcrumbs = [
    <Link to="/modules" key="home-link"><HomeIcon /></Link>,
    <Typography fontWeight="bold" fontSize="small" key="3" color="text.primary">
      Modules
    </Typography>,
  ];

  const handleLogout = () => {
    logoutUser();
    onLogout();
  }

  return (
    <div className="moduleListContainer">
      <Box ml={2} mb={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <Button variant="contained" onClick={() => handleLogout()}>Logout</Button>
      </Box>
      <div className="moduleListHeader">
        <Typography variant="h4">My modules</Typography>
        <Button variant="contained" onClick={handleClickOpen}>
          Create Module
        </Button>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Active" {...a11yProps(1)} />
          <Tab label="Drafts" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ModuleScroll modules={moduleList || []} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
      <CreateDialog
        open={open}
        onClose={() => setOpen(false)}
        setModuleNameInReducer={setModuleName}
      />
    </div>
  );
}

export default ModuleList;
