import { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Card,
  TextField
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { Moment, ModuleScript } from "../../types";
import { ModuleEditorContext } from '../../reducers/ModuleEditorReducer';

const StyledCard = styled(Card)({
  width: "100%",
  height: "auto",
  minHeight: "150px",
  backgroundColor: "rgb(240, 240, 240)",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start", // Aligns items to the left
});

const HeaderContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "15px",
});

function LearnerText({ value, setValue, save }: { value: string, setValue: (value: string) => void, save: () => void }) {
  return (
    <TextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 300 }}
      multiline
      rows={6}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => save()}
    />
  );
}

function CharacterText({ value, setValue, save }: { value: string, setValue: (value: string) => void, save: () => void }) {
  return (
    <TextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 300 }}
      multiline
      rows={12}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => save()}
    />
  );
}

export default function MomentCard({
  pathA,
  pathB,
  pathC,
  momentNumber,
}: {
  pathA: Moment,
  pathB: Moment,
  pathC: Moment,
  momentNumber: number;
}) {
  const context = useContext(ModuleEditorContext);

  if (!context) {
    throw new Error("MomentCard must be used within a ModuleEditorProvider");
  }

  const { dispatch, state } = context;
  const { communicationStyleA, communicationStyleB, communicationStyleC, keyMoments } = state;

  const [isChecked, setChecked] = useState(keyMoments.has(momentNumber));
  const [editableTextPathALearner, setEditableTextPathALearner] = useState(pathA.learner_text);
  const [editableTextPathBLearner, setEditableTextPathBLearner] = useState(pathB.learner_text);
  const [editableTextPathCLearner, setEditableTextPathCLearner] = useState(pathC.learner_text);
  const [editableTextPathACharacter, setEditableTextPathACharacter] = useState(pathA.character_text);
  const [editableTextPathBCharacter, setEditableTextPathBCharacter] = useState(pathB.character_text);
  const [editableTextPathCCharacter, setEditableTextPathCCharacter] = useState(pathC.character_text);

  console.log('rerender', { pathA, pathB, pathC, momentNumber });

  useEffect(() => {
    setEditableTextPathALearner(pathA.learner_text);
}, [pathA.learner_text]);

  useEffect(() => {
      setEditableTextPathBLearner(pathB.learner_text);
  }, [pathB.learner_text]);

  useEffect(() => {
      setEditableTextPathCLearner(pathC.learner_text);
  }, [pathC.learner_text]);

  useEffect(() => {
      setEditableTextPathACharacter(pathA.character_text);
  }, [pathA.character_text]);

  useEffect(() => {
      setEditableTextPathBCharacter(pathB.character_text);
  }, [pathB.character_text]);

  useEffect(() => {
      setEditableTextPathCCharacter(pathC.character_text);
  }, [pathC.character_text]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const newKeyMoments = new Set(keyMoments);

    if (event.target.checked) {
      newKeyMoments.add(momentNumber);
    } else {
      newKeyMoments.delete(momentNumber);
    }

    dispatch({
      type: "SET_KEY_MOMENTS",
      payload: newKeyMoments,
    })
  };

  return (
    <Grid container spacing={2} alignItems="center" mt={2}>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography className="character">Moment {momentNumber + 1}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled={keyMoments.size >= 3 && !isChecked}
            />
          }
          label="Key Moment"
        />
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard sx={{ backgroundColor: "#c2f0c9" }}>
          <HeaderContainer>
            <Typography fontWeight="bold">{communicationStyleA}</Typography>
          </HeaderContainer>
          <LearnerText value={editableTextPathALearner} setValue={setEditableTextPathALearner} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathA",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathA,
                  learner_text: editableTextPathALearner,
                }
              }
            });
          }}
          />
          {/* <TextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 100 }}
            multiline
            rows={4}
            value={editableTextPathALearner}
            onChange={(e) => setEditableTextPathALearner(e.target.value)}
            onBlur={() => {
              dispatch({
                type: "EDIT_MOMENT",
                payload: {
                  path: "pathA",
                  momentIndex: momentNumber,
                  newMoment: {
                    ...pathA,
                    learner_text: editableTextPathALearner,
                  }
                }
              });
            }}
          /> */}
        </StyledCard>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard sx={{ backgroundColor: "#f0f0b5" }}>
          <HeaderContainer>
            <Typography fontWeight="bold">{communicationStyleB}</Typography>
          </HeaderContainer>
          <LearnerText value={editableTextPathBLearner} setValue={setEditableTextPathBLearner} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathB",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathB,
                  learner_text: editableTextPathBLearner,
                }
              }
            });
          }}
          />
        </StyledCard>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard sx={{ backgroundColor: "#f0c2c2" }}>
          <HeaderContainer>
            <Typography fontWeight="bold">{communicationStyleC}</Typography>
          </HeaderContainer>
          <LearnerText value={editableTextPathCLearner} setValue={setEditableTextPathCLearner} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathC",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathC,
                  learner_text: editableTextPathCLearner,
                }
              }
            });
          }}
          />
        </StyledCard>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard>
          <HeaderContainer>
            <Typography fontWeight="bold">Response</Typography>
          </HeaderContainer>
          <CharacterText value={editableTextPathACharacter} setValue={setEditableTextPathACharacter} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathA",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathA,
                  character: editableTextPathACharacter,
                }
              }
            });
          }}
        />
        </StyledCard>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard>
          <HeaderContainer>
            <Typography fontWeight="bold">Response</Typography>
          </HeaderContainer>
          <CharacterText value={editableTextPathBCharacter} setValue={setEditableTextPathBCharacter} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathB",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathB,
                  character: editableTextPathBCharacter,
                }
              }
            });
          }}
        />
        </StyledCard>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        <StyledCard>
          <HeaderContainer>
            <Typography fontWeight="bold">Response</Typography>
          </HeaderContainer>
          <CharacterText value={editableTextPathCCharacter} setValue={setEditableTextPathCCharacter} save={() => {
            dispatch({
              type: "EDIT_MOMENT",
              payload: {
                path: "pathC",
                momentIndex: momentNumber,
                newMoment: {
                  ...pathC,
                  character: editableTextPathCCharacter,
                }
              }
            });
          }}
        />
        </StyledCard>
      </Grid>
    </Grid>
  );
}