import { Auth } from 'aws-amplify';

// export default function loginUser({ username, password }: { username: string, password: string }) {
//   return fetch(`${process.env.REACT_APP_API_URL}/login`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       username: username,
//       password: password
//     })
//   })
//     .then(data => data.json())
// }

export default async function loginUser({ username, password }: { username: string, password: string }) {
  try {
      const user = await Auth.signIn(username, password);
      // If successful, user object will have all the details of the authenticated user.
      // You might want to extract the JWT tokens or any other details you need here.
      return user;
  } catch (error) {
      console.error('Error logging in:', error);
      throw error;  // or handle the error as per your needs
  }
}

export async function logoutUser() {
  try {
      await Auth.signOut();
  } catch (error) {
      console.error('Error logging out:', error);
      throw error;  // or handle the error as per your needs
  }
}