import { createContext } from "react";
import { produce, Draft } from "immer";
import {
  ModuleScript,
  Moment,
  Message,
  ModuleDisplay,
} from "../types";
import { prompt1, response1, prompt2, response2 } from "../prompts/generateScriptPrompt";

export type ModuleEditorReducer = {
  moduleName: string;
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
  moduleScript: ModuleScript;
  scriptLoading: boolean;
  summary: string;
  videosLoading: boolean;
  messages: Message[];
  character?: string;
  environment?: string;
  moduleList?: ModuleDisplay[];
  inProgressIds?: string[];
  surveyQuestions: string[];
  keyMoments: Set<number>;
  thumbnailUrl?: string;
  learningObjective?: string;
  topic?: string;
  location?: string;
  learnerRole?: string;
  conflictArea?: string;
  scenario?: string;
  incitingIncident?: string;
  characterPersona?: string;
  characterName?: string;
  voiceId?: string;
};

const initialMessage = {
  content: `
  You are a helpful assistant, and your goal is to guide the user through filling out a form, ModuleData, represented by this Pydantic type:

  PYDANTIC TYPE FOR MODULEDATA
  class ModuleMetadata(BaseModel):
  topic: str
  location: str
  learner_role: str
  learning_objective: str
  conflict_area: str
  scenario: str
  inciting_incident: str
  character_persona: str
  END PYDANTIC TYPE FOR MODULEDATA

  The fields, in order, are: topic, location, learner_persona, learning_objective, conflict_area, scenario, inciting_incident, character_persona. This form represents configuration for a learning module which will be turned into a script for an interactive professional training experience.
  Here's an example of a completed ModuleData configuration:

  sample_module = {
  'topic': 'Emotional Intelligence',
  'location': 'Breakroom',
  'learner_persona': 'First-time manager',
  'learning_objective': 'Manage emotions',
  'conflict_area': 'Miscommunication',
  'scenario': 'The learner is a mid-level employee in a medium-sized company. She has been working with a difficult manager who has been giving her unclear instructions and has made her feel unappreciated and undervalued.',
  'inciting_incident': 'The learner is a mid-level employee in a medium-sized company. She has been working with a difficult manager who has been giving her unclear instructions and has made her feel unappreciated and undervalued.',
  'character_persona': 'Miranda Priestley from the Devil Wears Prada',
  }

  Your job is to help the user fill out a form like this based on what they want their Module to concentrate on. You must always offer 3 suggestions for each field, except learning objective, for which you should offer 5 suggestions. If the user responds with a number, like
  1, 2, or 3, that means just use that suggestion. They may also say something like "Let's use the first one."
  You will move through each field sequentially as represented in the Pydantic class.

  Once you have gathered all eight fields, you will return them via a function call. You will call the function set_metadata. The function takes nine
  arguments, with eight of them corresponding to the module metadata fields, and the ninth argument is a response, which is a string that will be displayed to the user saying something like "You have filled out all the required
  fields, thank you."
  `,
  role: "system",
};

const setupMessage = {
  content:
    "Welcome to Moth + Flame's AI conversation building tool. Step 1. What is the topic area?\n(Suggestions: Emotional Intelligence, Diversity & Inclusion, Growth Mindset, Customer Experience, Sales)",
  role: "system",
};

const initialModuleScript = {
  pathA: {
    moments: [
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      // You can add more Moments as needed for each path.
    ],
  },
  pathB: {
    moments: [
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      // ... and so on.
    ],
  },
  pathC: {
    moments: [
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      { moment: "", learner_text: "", character_text: "", feedback: "" },
      // ... and so on.
    ],
  },
  summary: "",
  moment0: "",
  // You can add more paths as needed.
};

export type ReducerAction =
  | { type: "SET_COMMUNICATION_STYLE_A"; payload: string }
  | { type: "SET_COMMUNICATION_STYLE_B"; payload: string }
  | { type: "SET_COMMUNICATION_STYLE_C"; payload: string }
  | { type: "GENERATE_SCRIPT_START" }
  | { type: "GENERATE_SCRIPT_SUCCESS"; payload: ModuleScript }
  | { type: "GENERATE_SCRIPT_FAILURE"; payload: any }
  | {
      type: "EDIT_MOMENT";
      payload: {
        path: "pathA" | "pathB" | "pathC";
        momentIndex: number;
        newMoment: Moment;
      };
    }
  | { type: "ADD_MESSAGE"; payload: Message }
  | { type: "SET_CHARACTER"; payload: string }
  | { type: "SET_MODULE_NAME"; payload: string }
  | { type: "EDIT_MOMENT0"; payload: string }
  | { type: "SET_MODULE_LIST"; payload: ModuleDisplay[] }
  | { type: "SET_IN_PROGRESS_IDS"; payload: string[] }
  | { type: "SET_SURVEY_QUESTIONS"; payload: string[] }
  | { type: "LOAD_MODULE", payload: ModuleEditorReducer }
  | { type: "SET_KEY_MOMENTS"; payload: Set<number> }
  | { type: "SET_THUMBNAIL_URL", payload: string }
  | { type: "SET_ENVIRONMENT", payload: string }
  | { type: "SET_SUMMARY", payload: string }
  | { type: "SET_LEARNING_OBJECTIVE", payload: string }
  | { type: "SET_FIELDS", payload: Partial<ModuleEditorReducer>}

type ModuleEditorContextType = {
  state: ModuleEditorReducer;
  dispatch: React.Dispatch<any>;
};

export const ModuleEditorContext = createContext<
  ModuleEditorContextType | undefined
>(undefined);

export const initialState: ModuleEditorReducer = {
  moduleName: "TestModule1",
  communicationStyleA: "Empathetic",
  communicationStyleB: "Rude",
  communicationStyleC: "Hostile",
  moduleScript: initialModuleScript,
  scriptLoading: false,
  summary: "",
  videosLoading: false,
  // messages: [initialMessage, setupMessage],
  messages: [prompt1, response1, prompt2, response2 ],
  surveyQuestions: ["", ""],
  keyMoments: new Set(),
  character: undefined,
  environment: undefined,
  learningObjective: undefined,
};

export const setFields = (fields: Partial<ModuleEditorReducer>) => ({
  type: "SET_FIELDS",
  payload: fields,
});

export default function moduleEditorReducer(
  state: ModuleEditorReducer,
  action: ReducerAction
): ModuleEditorReducer {
  return produce(state, (draft: Draft<ModuleEditorReducer>) => {
    console.log(action.type);
    console.log(action);
    switch (action.type) {
      case "SET_COMMUNICATION_STYLE_A":
        draft.communicationStyleA = action.payload;
        break;
      case "SET_COMMUNICATION_STYLE_B":
        draft.communicationStyleB = action.payload;
        break;
      case "SET_COMMUNICATION_STYLE_C":
        draft.communicationStyleC = action.payload;
        break;
      case "SET_SUMMARY":
        draft.summary = action.payload;
        break;
      case "GENERATE_SCRIPT_START":
        draft.scriptLoading = true;
        break;
      case "GENERATE_SCRIPT_SUCCESS":
        draft.scriptLoading = false;
        draft.moduleScript = action.payload;
        draft.summary = action.payload.summary;
        break;
      case "GENERATE_SCRIPT_FAILURE":
        draft.scriptLoading = false;
        break;
      case "EDIT_MOMENT":
        const { path, momentIndex, newMoment } = action.payload;
        if (draft.moduleScript && draft.moduleScript[path]) {
          draft.moduleScript[path].moments[momentIndex] = newMoment;
        }
        break;
      case "EDIT_MOMENT0":
        draft.moduleScript.moment0 = action.payload;
        break;
      case "ADD_MESSAGE":
        draft.messages.push(action.payload);
        break;
      // case "UPDATE_MODULE_METADATA":
      //   Object.assign(draft, action.payload)
      //   break;
      case "SET_CHARACTER":
        draft.character = action.payload;
        break;
      case "SET_MODULE_NAME":
        draft.moduleName = action.payload;
        break;
      case "SET_MODULE_LIST":
        draft.moduleList = action.payload;
        break;
      case "SET_IN_PROGRESS_IDS":
        draft.inProgressIds = action.payload;
        break;
      case "SET_SURVEY_QUESTIONS":
        draft.surveyQuestions = action.payload;
        break;
      case "LOAD_MODULE":
        Object.assign(draft, action.payload);
        break;
      case "SET_KEY_MOMENTS":
        draft.keyMoments = action.payload;
        break;
      case "SET_THUMBNAIL_URL":
        draft.thumbnailUrl = action.payload;
        break;
      case "SET_ENVIRONMENT":
        draft.environment = action.payload;
        break;
      case "SET_LEARNING_OBJECTIVE":
        draft.learningObjective = action.payload;
        break;
      case "SET_FIELDS":
        Object.assign(draft, action.payload);
        break;
      default:
        throw Error("Unknown action");
    }
  });
}
