import { useState, useContext } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { ModuleEditorContext } from "../reducers/ModuleEditorReducer";

import { ModuleDisplay } from "../types";

type ModuleDisplayCardProps = {
  module: ModuleDisplay;
};

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "0.5rem",
});

type SimpleDialogProps = {
  onClose: (value: string) => void;
  selectedValue: string;
  open: boolean;
  moduleId: string;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;
  const navigate = useNavigate();

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      "ModuleDisplayCard must be used within a ModuleEditorContext.Provider"
    );
  }


  const { dispatch, state } = context;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const fetchModuleMetadata = async (moduleName: string) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/retrieve_module_metadata?module_name=${moduleName}`);
    const json = await res.json();
    console.log(json);
    dispatch({
      type: "LOAD_MODULE",
      payload: {
        moduleScript: json.moduleScript,
        communicationStyleA: json.communicationStyleA,
        communicationStyleB: json.communicationStyleB,
        communicationStyleC: json.communicationStyleC,
        moduleName: json.moduleName,
        thumbnailUrl: json.thumbnailUrl,
        summary: json.summary,
        learningObjective: json.learningObjective,
        character: json.character
      }
    })

  }

  const handleListItemClick = (action: string, moduleId: string) => {
    switch (action) {
      case "edit":
        navigate(`/module/${moduleId}`)
        break;
      case "editName":
        console.log("editName");
        break;
      case "demo":
        console.log("demo");
        break;
      case "copyLink":
        console.log("copyLink");
        break;
      case "activate":
        console.log("activate");
        break;
      case "delete":
        console.log("delete");
        break;
      default:
        console.log("default");
        break;
    }
  };

  const options = [
    "Edit module",
    "Edit module name",
    "Demo module",
    "Copy share link",
    "Activate experience",
    "Delete module"
  ]

  // TODO
  const icons = [
    <AppRegistrationIcon />,
    <EditOutlinedIcon />,
    <SmartDisplayOutlinedIcon />,
    <LinkOutlinedIcon />,
    <CellTowerOutlinedIcon />,
    <DeleteOutlineOutlinedIcon />
  ]

  const actions = [
    "edit",
    "editName",
    "demo",
    "copyLink",
    "activate",
    "delete"
  ]

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>Set backup account</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((option, idx) => (
          <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => handleListItemClick(actions[idx], props.moduleId)}
          >
            <ListItemAvatar>
              <Avatar>
                {icons[idx]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={option} />
          </ListItemButton>
        </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

function ModuleDisplayCard({ module }: ModuleDisplayCardProps) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <StyledCard>
      <CardMedia
        component="img"
        height="150"
        sx={{ width: 152 }}
        image={module.thumbnail_url
          ? `${process.env.REACT_APP_ASSETS_URL}${module.thumbnail_url}`
          : "/logo192.png"}
        alt="Module thumbnail"
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" color="text.secondary">
          {module.module_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {module.summary}
        </Typography>
      </CardContent>
      <IconButton
        aria-label="settings"
        sx={{ alignSelf: "center" }}
        onClick={handleClickOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        moduleId={module.module_name}
      />
    </StyledCard>
  );
}

export default ModuleDisplayCard;
