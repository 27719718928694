import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Card,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  Box
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ModuleEditorContext } from "../../reducers/ModuleEditorReducer";

function ModuleThumbnail() {
  const context = useContext(ModuleEditorContext);

  if (!context) {
    throw new Error("MomentCard must be used within a ModuleEditorProvider");
  }

  const { state, dispatch } = context;

  // State to manage the uploaded image's URL
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (state.thumbnailUrl) {
      console.log('has');
      setImageURL(`${process.env.REACT_APP_ASSETS_URL}/${state.thumbnailUrl}`);
    }
  }, [state.thumbnailUrl]);

  // Function to handle image changes
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log('file');
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('set image url');
        setImageURL(reader.result as string);
        // Use the file variable directly here
        console.log('postimage');
        postImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setImageURL(null);
    dispatch({
      type: "SET_THUMBNAIL_URL",
      payload: null,
    })
  };

  const postImage = async (file: File) => {
    const formData = new FormData();
    formData.append('thumbnail', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save_thumbnail?module_name=${state.moduleName}`, {
        method: 'POST',
        body: formData
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to upload image.');
      }
      dispatch({
        type: "SET_THUMBNAIL_URL",
        payload: responseData.thumbnail_url,
      })
      console.log('Image uploaded successfully:', responseData);
    } catch (err: any) {
      console.error('Upload error:', err.message);
    }
  };

  return (
    <Card
      sx={{
        padding: 5,
        my: 2,
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h5">Module Thumbnail</Typography>
      <Typography variant="body1" sx={{ color: "grey", mb: 2 }}>
        Below is how your module's thumbnail will look. Upload your own to customize.
      </Typography>
      {imageURL && (
        <Box position="relative" mb={2}>
          <img src={imageURL} alt="Uploaded thumbnail" style={{ width: '300px', height: 'auto', borderRadius: '10px' }} />
          {/* Uncomment this button if you want to add delete functionality again
          <Button
            onClick={handleDeleteImage}
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
            color="error"
          >
            Delete
          </Button>
          */}
        </Box>
      )}
      <Button
        variant="outlined"
        component="label"
      >
        Upload Thumbnail
        <input
          type="file"
          hidden
          onChange={handleImageChange}
          accept="image/*"
        />
      </Button>
    </Card>
  );
}

export default ModuleThumbnail;