import { useContext } from 'react';
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Card,
  Grid,
  Button,
  InputLabel,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { ModuleEditorContext } from "../../reducers/ModuleEditorReducer";
import generateScriptPrompt from '../../prompts/generateScriptPrompt';

type CommunicationStylesProps = {
  communicationStyleAOptions: string[];
  communicationStyleBOptions: string[];
  communicationStyleCOptions: string[];
};

const CommunicationStyleCard = styled(Card)({
  width: "100%",
  height: "auto",
  minHeight: "50px",
  backgroundColor: "rgb(240, 240, 240)",
  borderRadius: "10px",
  marginBottom: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

function CommunicationStyles(props: CommunicationStylesProps) {
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error("CommunicationStyles must be used within a ModuleEditorContext.Provider");
  }

  const { state, dispatch } = context;
  const { communicationStyleA, communicationStyleB, communicationStyleC, moduleName } = state;

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    actionType: string,
  ) => {
    dispatch({
      type: actionType,
      payload: event.target.value as string,
    })
  };

  const handleGenerateScript = async () => {
    const message = generateScriptPrompt(state);

    dispatch({
      type: 'GENERATE_SCRIPT_START',
    })
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/generate_script`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: message,
          styleA: communicationStyleA,
          styleB: communicationStyleB,
          styleC: communicationStyleC,
          moduleName: moduleName
        })
      });
      const json = await res.json();
      dispatch({
        type: 'GENERATE_SCRIPT_SUCCESS',
        payload: json,
      });
    } catch (error) {
      dispatch({
        type: 'GENERATE_SCRIPT_FAILURE',
        payload: error,
      });
    }
  }

  return (
    <Card
      sx={{
        padding: 2,
        my: 2,
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h5">Communication Styles</Typography>
      <Grid container spacing={2} alignItems="center" mt={2}>
        <Grid item xs={4} container justifyContent="center">
          <CommunicationStyleCard>
            <FormControl fullWidth>
              <InputLabel id="ideal-choice-label">Ideal choice</InputLabel>
              <Select
                labelId="ideal-choice-label"
                data-testid="ideal-choice-select"
                id="ideal-choice"
                value={communicationStyleA}
                label="ideal-choice-label"
                onChange={(e) =>
                  handleSelectChange(e, 'SET_COMMUNICATION_STYLE_A')
                }
                sx={{ borderRadius: "0px", backgroundColor: "white" }}
              >
                {props.communicationStyleAOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationStyleCard>
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <CommunicationStyleCard>
            <FormControl fullWidth>
              <InputLabel id="suboptimal-choice-label">Suboptimal choice</InputLabel>
              <Select
                labelId="suboptimal-choice-label"
                data-testid="suboptimal-choice-select"
                id="suboptimal-choice"
                value={communicationStyleB}
                label="suboptimal-choice-label"
                onChange={(e) =>
                  handleSelectChange(e, 'SET_COMMUNICATION_STYLE_B')
                }
                sx={{ borderRadius: "0px", backgroundColor: "white" }}
              >
                {props.communicationStyleBOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationStyleCard>
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <CommunicationStyleCard>
            <FormControl fullWidth>
              <InputLabel id="bad-choice-label">Bad choice</InputLabel>
              <Select
                labelId="bad-choice-label"
                data-testid="bad-choice-select"
                id="bad-choice"
                value={communicationStyleC}
                label="bad-choice-label"
                onChange={(e) =>
                  handleSelectChange(e, 'SET_COMMUNICATION_STYLE_C')
                }
                sx={{ borderRadius: "0px", backgroundColor: "white" }}
              >
                {props.communicationStyleCOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationStyleCard>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button onClick={handleGenerateScript}>Generate Script</Button>
      </Box>
    </Card>
  );
}

export default CommunicationStyles;
